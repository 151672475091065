<template>
    <v-card>
        <v-card-title><span>RECHARGER UN UTILISATEUR</span></v-card-title>
        <v-card-text>
            <v-container>
                <form>
                    <div class="form-group">
                        <label>Réference du rechargement:</label>
                        <v-select :items="labelTransaction" item-text="label" item-value="label" type="text" :error-messages="labelDepositError" dense outlined v-model.trim="$v.labelDeposit.$model"></v-select>
                        <small id="emailHelp" class="form-text text-muted">Saisissez un libellé pour le rechargement? </small>
                    </div>

                    <div class="form-group">
                        <label>Nombre de jetons:</label>
                        <v-select :items="tokenItems" item-text="amount" item-value="amount" type="number" min="0" :error-messages="tokenToSendError" dense outlined v-model.number="$v.tokenToSend.$model"></v-select>
                        <small id="emailHelp" class="form-text text-muted">Combien de <strong>ARI</strong> voulez vous envoyer ? </small>
                    </div>
                    <small id="emailHelp" class="form-text text-muted">NB : (*) devant un champs signifie qu'il est obligatoire.</small>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn variant="text" color="orange" @click="sendTokenToUser(tokenToSend,labelDeposit,userTransaction.userCode)">RECHARGER</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { baseUrl } from '@/Config/globalConst';
import axios from 'axios';
import $ from 'jquery';
import { numeric, required } from 'vuelidate/lib/validators';
export default {
    props:{
        userTransaction: Object,
    },
    name:"TransfertToken",
    data(){
        return{
            labelTransaction : [
                { label: "Achat de jetons" },
                { label : "Bonus "}
            ],

            tokenItems : [
                { amount : 3},
                { amount : 5},
                { amount : 10}
            ],
        }
    },

    validations:{
        tokenToSend:{
            required , numeric
        },

        labelDeposit:{
            required
        }
    },

    methods:{

        // ENVOYER LES JETONS A UN UTILISATEUR
        async sendTokenToUser(amount , label , userCode){
            this.$v.$touch();
            if(this.$v.labelDeposit.$invalid){
                this.errorMsg = "Selectionnez un label"
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }else if(this.$v.tokenToSend.$invalid){
                this.errorMsg = "Le champ de jetons est obligatoire"
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000)
            }else{
                this.$swal({
                    title: "Envoyer les jetons?", text: "Êtes vous sure ? Attention cette action est irréversible",
                    type: "Warning", showCancelButton: true,
                    confirmButtonColor: "#c0392b",confirmButtonText: "Oui, Recharger!"
                }).then((result) => {
                    this.overlay = true;
                    if (result.value) {
                        let graphqlQuery = {
                            query : `mutation{
                                payTokens(amount:${amount}, label:"${label}", userCode:"${userCode}"){
                                    success
                                }
                            }`
                        }
                        axios.post(baseUrl, graphqlQuery).then((response) =>{
                            if(response.data.data.payTokens.success === true){
                                this.$swal.fire('Rechargement Reussi' , "L' utilisateur a été rechargé"  , 'success')
                            }else{
                                this.$swal.fire('Rechargement echouée' , "Problème Interne" , 'error')
                            }
                        }).catch((e)=>{
                            this.$swal.fire('Rechargement echouée' , e , 'error')
                        })
                    }
                })
            }
        },

    },

    computed:{
        labelDepositError(){
            if (!this.$v.labelDeposit.$dirty) return [];
            const errors =  [] ;
            !this.$v.labelDeposit.required && errors.push("Choisissez une reference !");
            return errors ;
        },

        tokenToSendError(){
            if (!this.$v.tokenToSend.$dirty) return [];
            const errors =  [] ;
            !this.$v.tokenToSend.required && errors.push(" Combien de jetons voulez-vous envoyer ?");
            !this.$v.tokenToSend.numeric && errors.push(" Mauvaise saisie. le token doit être un nombre !");
            return errors ;
        },
    }
}
</script>