<template>
    <v-app>
        <v-container>
            <v-main>
                <div class="row">
                    <div class="col-lg-3">
                        <span class="font-weight-bold">Total Téléchargement : <small class="count">{{ downlaodCvCount }}</small></span>
                    </div>
                    <div class="col-lg-3">
                        <span class="font-weight-bold">Total Modification : <small class="count">{{ updateCvCount }}</small></span>
                    </div>
                    <div class="col-lg-3">
                        <span class="font-weight-bold">Total Partages : <small class="count">{{ shareCvCount }}</small></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <v-card>
                            <v-img :src="cvSelected.image"></v-img>
                        </v-card>
                    </div>
                    <div class="col-lg-9">
                        <v-card>
                            <v-card-title><span class="title-card">Information du CV n°{{ $route.params.cvModel}}</span>
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table :headers="headers" :items="usersByCvList" :search="search" :loading="loading">
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </v-main>
        </v-container>
    </v-app>
</template>

<script>
import axios from 'axios'
import { baseUrl , cvInfosByUser } from '../Config/globalConst'
export default {
    name:"CvItemDetail",
    data(){
        return {
            search:'',
            loading:false,
            headers:[
                { text: 'Nom', value: 'user.name' },
                { text: 'Prenoms', value: 'user.firstname' },
                { text: 'Téléchargement', value: 'downloadCount' },
                { text: 'Modification', value: 'updateCount' },
                { text: 'Partages', value: 'shareCount' },
            ],

            usersByCvList: [],

            cvList:[
                { groupKey:"001", image : null,},{ groupKey:"002", image : null,},{ groupKey:"003", image : null,},
                { groupKey:"004", image : null,},{ groupKey:"005", image : null,},{ groupKey:"006", image : null,},
                { groupKey:"007", image : null,},{ groupKey:"008", image : null,},{ groupKey:"009", image : null,},
                { groupKey:"010", image : null,},{ groupKey:"011", image : null,},{ groupKey:"012", image : null,},
                { groupKey:"013", image : null,},{ groupKey:"014", image : null,},{ groupKey:"015", image : null,},
                { groupKey:"016", image : null,},{ groupKey:"017", image : null,},{ groupKey:"018", image : null,},
                { groupKey:"019", image : null,},{ groupKey:"020", image : null,},
            ],

            cvSelected: {
                groupKey : null,
                image: null
            }
        }
    },

    methods: {
        // OBTENIR LA LISTE DES CV PAR MODELS
        async getAllCvInfosModel(){
            this.cvList.forEach(element => {
                if (element.groupKey == this.$route.params.cvModel) {
                    this.cvSelected.image = "https://images.ariahapp.com/support/images/"+element.groupKey+".jpg"
                }
            });
        },

        // OBTENIR LA LISTE DES UTILISATEURS AYANT UTILISÉS LE MODEL DE CV
        async getAllUserByCvModel(){
            this.loading = true;
            let graphqlQuery = {
                query : cvInfosByUser("cv_model" , this.$route.params.cvModel)
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                console.log(response)
                if (response.status == 200) {
                    this.usersByCvList = response.data.data.cvInfosByUser[0].userCounts
                }
            }).catch((err) =>{
                console.log(err)
            }).finally(() =>{
                this.loading = false
            })
        }
    },

    computed:{
        downlaodCvCount(){
            let cvDownloadCount = 0;
            this.usersByCvList.forEach(element => {
                cvDownloadCount += element.downloadCount
            });
            return cvDownloadCount;
        },

        updateCvCount(){
            let cvUpdateCount = 0;
            this.usersByCvList.forEach(element => {
                cvUpdateCount += element.updateCount
            });
            return cvUpdateCount;
        },

        shareCvCount(){
            let shareCvCount = 0;
            this.usersByCvList.forEach(element => {
                shareCvCount += element.shareCount
            });
            return shareCvCount;
        },
    },

    mounted(){
        this.getAllCvInfosModel();
        this.getAllUserByCvModel();
    }
}
</script>

<style scoped>
    .title-card{
        font-size: 18px;
        color: #2f3542;
        font-weight: bold;
    }

    .count{
        font-size: 20px;
        color: #222f3e;
    }
</style>