<template>
    <v-app>
        <v-main>
            <v-sheet class="mx-auto pa-2 pt-6" color="grey-lighten-4">
                <div class="row">
                    <div class="col-sm">
                        <div class="usage"></div>
                        <v-card elevation="3">
                            <v-card-title><h3>TRADUCTION</h3></v-card-title>
                            <v-card-subtitle>Jetons utilisés pour les traductions</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenTraductionCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ totalCoinUsedForTranslate.length }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <div class="col-sm">
                        <v-card elevation="3">
                            <div class="usage"></div>
                            <v-card-title><h3>CORRECTION</h3></v-card-title>
                            <v-card-subtitle>Jetons utilisés pour les corrections</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenCorrectionCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ totalCoinUsedForCorrection.length }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <div class="col-sm">
                        <v-card elevation="3">
                            <div class="free"></div>
                            <v-card-title><h3>JETONS OFFERTS</h3></v-card-title>
                            <v-card-subtitle>Jetons offerts aux utilisateurs</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokensFreeCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ amountFree }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                </div><br>

                <div class="row">
                    <div class="col-sm-4">
                        <div class="blocked"></div>
                        <v-card elevation="3">
                            <v-card-title><h3>JETONS BLOQUES</h3></v-card-title>
                            <v-card-subtitle>Total des jetons bloqués</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenBlockedCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ amountBlocked }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <div class="col-sm-4">
                        <div class="usage"></div>
                        <v-card elevation="3">
                            <v-card-title><h3>TOTAL UTILISATION EN XOF</h3></v-card-title>
                            <v-card-subtitle>Total des jetons utilisés en XOF</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenBlockedCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ totalPriceTranslate + totalPriceCorrect }} XOF</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <div class="col-sm-4">
                        <div class="free"></div>
                        <v-card elevation="3">
                            <v-card-title><h3>TOTAL JETONS DISTRIBUES</h3></v-card-title>
                            <v-card-subtitle>Total des jetons distribué en XOF</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenBlockedCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ totalPriceForFree }} XOF</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                </div><br>


                

            </v-sheet>
            <v-container fluid>
                <div class="row">
                    <div class="col">
                        <v-card>
                            <v-card-title><h4>HISTORIQUES DES TRANSACTIONS</h4>
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                            </v-card-title>
                            <v-data-table 
                                :single-select="singleSelect" show-select 
                                :headers="headers" :search="search" 
                                :items="transactionList" :loading="loading"
                                item-key="node.id" v-model="selected"
                            >
                                <template v-slot:[`item.transactionType`]="{ item }">
                                    <v-chip x-small v-if="item.transactionType == 'Achat'" color="success"><span class="type font-weight-bold">{{ item.transactionType }}</span></v-chip>
                                    <v-chip x-small v-if="item.transactionType == 'Bloqué'" color="red"><span class="type font-weight-bold">{{ item.transactionType }}</span></v-chip>
                                    <v-chip x-small v-if="item.transactionType == 'Usage'" color="warning"><span class="type font-weight-bold">{{ item.transactionType }}</span></v-chip>
                                </template>
                            </v-data-table>
                        </v-card>
                    </div>
                </div>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { baseUrl } from '@/Config/globalConst';
import axios from 'axios';
//import $ from 'jquery';
export default {
    name:"transaction",
    data(){
        return{

            singleSelect:false,

            isLoadingTokenTraductionCount: false,
            isLoadingTokenCorrectionCount: false,
            isLoadingTokensFreeCount : false,
            isLoadingTokenBlockedCount : false,

            dialogNotification : false ,
            dialogNotificationUser : false ,
            dialogNotificationGroupUser : false ,
            sendEmailDynamicDialog: false,

            

            loading : false,
            search : '',

            headers:[
                { text: 'Nom', value: 'user.name' },
                { text: 'Prenoms', value: 'user.firstname' },
                { text: 'Ref Transaction', value: 'transactionType' },
                { text: 'Libelle', value: 'label' },
                { text: 'Nbre jetons', value: 'amount' },
                { text: 'Prix', value: 'price' },
                { text: 'Date', value: 'timestamp' },
            ],

            transactionList : [],
            selected:[],

            amountFree : 0 ,
            amountBlocked : 0 ,
            totalPriceTranslate : 0 , 
            totalPriceCorrect : 0 ,
            totalPriceForFree : 0, 

            totalCoinUsedForTranslate : [],
            totalCoinUsedForCorrection :[],
            totalCoinsForFree : [],
            totalCoinsBlocked : [],
            totalPriceForCorrection:[],
            totalPriceForFreeCoins:[],
        }
    },

    methods:{
        // RECUPERER LA LISTE DES TRANSACTIONS
        async getAllTransactions(){
            this.loading = true;
            this.isLoadingTokenTraductionCount = true;
            this.isLoadingTokenCorrectionCount = true;
            this.isLoadingTokensFreeCount = true;
            let graphqlQuery = {
                "query" : "query{allTransactionHistory{user { name firstname email } amount label price transactionType timestamp } }"
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                response.data.data.allTransactionHistory.forEach(element => {
                    if(element != null){
                        if (element.label === 'Traduction de CV') {
                            this.totalCoinUsedForTranslate.push(element);
                            this.totalPriceTranslate = ( element.amount * 250 ) + this.totalPriceTranslate
                        }
                        if (element.label === 'Correction de CV') {
                            this.totalCoinUsedForCorrection.push(element);
                            this.totalPriceCorrect = ( element.amount * 250 ) + this.totalPriceCorrect
                        }
                        if (element.label === 'Jeton d\'inscrition !') {
                            this.totalCoinsForFree.push(element);
                            this.amountFree = element.amount + this.amountFree
                            this.totalPriceForFree = ( element.amount * 250 ) + this.totalPriceForFree
                            
                        }
                        if (element.label === 'Jétons Bloqués') {
                            this.totalCoinsBlocked.push(element);
                            this.amountBlocked = element.amount + this.amountBlocked
                        }
                        this.transactionList.push(element);
                        
                    }
                });
            }).catch((e)=>{
                console.log(e)
                /*this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);*/
            }).finally(()=>{
                this.isLoadingTokenTraductionCount = false ;
                this.isLoadingTokenCorrectionCount = false;
                this.isLoadingTokensFreeCount = false;
                this.loading = false
            })
        }

    },

    mounted(){
        this.getAllTransactions();
    }
}
</script>

<style>
    .type{
        color:white
    }

    .label{
        font-weight: bold;
        font-size: 35px;
    }

    .usage{
        background: orange;
        height: 10px;
    }

    .blocked{
        background: red;
        height: 10px;
    }

    .free{
        height: 10px;
        background: green;
    }
</style>