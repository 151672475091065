<template>
    <v-app>
        <v-container fluid>
            <div class="row">
                <div class="col-sm-8">
                    <v-card>
                        <v-card-title><span class="title-card">Gestion des bugs signalés</span>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="headers" :items="bugsList"  :search="search" :loading="loading">
                                <template v-slot:[`item.node.resolved`]="{ item }">
                                    <v-chip x-small v-if="item.node.resolved === 'True'" color="success"><span class="type font-weight-bold">Géré</span></v-chip>
                                    <v-chip x-small v-if="item.node.resolved === 'False'" color="red"><span class="type font-weight-bold">Non géré</span></v-chip>
                                </template>
                                <template v-slot:[`item.actions`]="{item}">
                                    <v-btn btn x-small color="secondary" @click="voirDetailBug(item)">Voir plus</v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </div>

                <div class="col-sm-4" v-if="miniDialog == true">
                    <v-card hover>
                        <v-card-title>
                            <span class="title-label">Détail sur le bug</span>
                            <v-spacer></v-spacer>
                            <v-chip x-small v-if="bug.node.resolved === 'True'" color="success"><span class="type font-weight-bold">Géré</span></v-chip>
                            <v-chip x-small v-if="bug.node.resolved === 'False'" color="red"><span class="type font-weight-bold">Non géré</span></v-chip>
                            </v-card-title>
                        <v-card-text>
                            <v-list>
                                <v-container>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <span class="text-muted font-weight-bold">Catégorie :</span>
                                        </div>
                                        <div class="col-sm-8">
                                            <span class="text-muted">{{ bug.node.bugCat }}</span>
                                        </div>
                                    </div>
                                </v-container>
                                <v-container>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <span class="text-muted font-weight-bold">Description :</span>
                                        </div>
                                        <div class="col-sm-8">
                                            <span class="text-muted">{{ bug.node.description }}</span>
                                        </div>
                                    </div>
                                </v-container>
                                <v-container>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <span class="text-muted font-weight-bold">Signalé par :</span>
                                        </div>
                                        <div class="col-sm-8">
                                            <span class="text-muted">{{ bug.node.user.firstname + bug.node.user.name }}</span>
                                        </div>
                                    </div>
                                </v-container>
                            </v-list>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn x-small color="secondary" @click="miniDialog = false"><span>Fermer</span></v-btn>
                            <v-btn x-small color="warning" v-if="bug.node.resolved == 'False'" @click="resoudreUnBug(bug.node.bugCat)"><span>Gérer</span></v-btn>
                            <v-btn x-small color="warning" v-else-if="btnDisplay == true" @click="resoudreUnBug(bug.node.bugCat)"><span>Gérer</span></v-btn>
                            <v-btn x-small color="warning" v-else disabled><span>Gérer</span></v-btn>

                        </v-card-actions>
                    </v-card>
                </div>
            </div>
        </v-container>
        <v-alert type="error" class="alert error-alert" dismissible>{{ errorMsg }}</v-alert>
        <v-alert type="success" class="alert success-alert" dismissible>{{ successMsg }}</v-alert>

        <v-overlay :value="overlay">
            <v-progress-circular color="orange" indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-app>
    
</template>

<script>
import axios from 'axios'
import { baseUrl , allBugs } from '../Config/globalConst'
import $ from 'jquery'

export default {
    name:"Bugs.vue",
    data(){
        return{
            btnDisplay: false,
            loading : false,
            overlay: false,
            search:'',
            headers:[
                { text: 'categorie', value: 'node.bugCat' },
                { text: 'Etat', value: 'node.resolved' },
                { text: 'Date', value: 'node.creationDate' },
                { text: 'Actions', value: 'actions', sortable:false },
            ],

            miniDialog:false,
            errorMsg: null,
            successMsg: null,
            warningMsg: null,

            bugsList:[],
            selected:[],
            singleSelect: false,

            bug:{}
        }
    },

    validations:{

    },

    methods: {
    
        // GERER UN BUG SIGNALÉ
        async resoudreUnBug(bugCat){
            this.overlay = true
            let graphqlQuery = {
                query: `mutation {
                    updateBug(bugCat:"${bugCat}"){
                        success
                    }
                }`
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.status == 200){
                    if (response.data.data.updateBug.success === true) {
                        this.successMsg = "Le bug a été géré !"
                        $('.success-alert').fadeIn();
                        setTimeout(() => {
                            $('.success-alert').fadeOut();
                        }, 4000);
                        this.getAllBugsDetected();
                    }else{
                        this.errorMsg = "Erreur de modification de bug!"
                        $('.error-alert').fadeIn();
                        setTimeout(() => {
                            $('.error-alert').fadeOut();
                        }, 4000);
                        this.getAllBugsDetected();
                    }
                } 
            }).catch((e) => {
                this.errorMsg = e
                $('.error-alert').fadeIn();
                setTimeout(() => {
                    $('.error-alert').fadeOut();
                }, 4000);
            }).finally(() => {
                this.overlay = false;
            });
        },

        // RECUPERER LA LISTE DES BUGS DETECTER PAR LES UTILISATEURS
        async getAllBugsDetected(){
            this.loading = true
            let graphqlQuery = {
                "query" : allBugs
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.status === 200){
                    response.data.data.allBug.edges.forEach(element => {
                        if(element.node !== null){
                            element.node.creationDate = this.returnDateFormatted(element.node.creationDate)
                            this.bugsList.push(element)
                        }
                    });
                }
                else{
                    this.errorMsg = "Erreur serveur , actualisez"
                    $('.alert-error').fadeIn();
                    setTimeout(() => {
                        $('.error-alert').fadeOut();
                    }, 4000);
                    this.bugsList = [];
                }
            }).catch((e)=>{
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.error-alert').fadeOut();
                }, 4000);
                this.bugsList = []
            }).finally(()=>{
                this.loading = false
            })
        },

        //OUVRIR LE DIALOG D'UN BUG
        openMiniDialogBug(){
            this.miniDialog = true;
        },

        // VOIR DETAIL D'UN BUG
        voirDetailBug(item){
            this.openMiniDialogBug();
            this.bug = item;
        },

        // RETOURNER LA DATE SOUS FORME JJ-MM-AAAA
        returnDateFormatted(dateFormatted){
            let date = new Date(dateFormatted);
            let jour = date.getDate();
            let mois = date.getMonth() + 1 ;
            let annee = date.getFullYear();
            jour = String(jour).padStart(2 , '0');
            mois = String(mois).padStart(2 , '0');
            let dateFormat = `${annee}-${mois}-${jour}`;
            return dateFormat;
        },

    },

    mounted(){
        this.getAllBugsDetected();
    },
}
</script>

<style scoped>
    .alert{
        z-index: 2100;
        width: 20%;
    }

    .error-alert{
        position: fixed;
        display: none;
        right: 0;
    }

    .success-alert{
        position: fixed;
        display: none;
        right: 0;
    }


    .title-card{
        font-size: 18px;
        color: #2f3542;
        font-weight: bold;
    }

    .title-label{
        font-size: 15px;
        color: #2f3542;
        font-weight: bold;
    }

</style>