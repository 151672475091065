<template>
    <v-card>
        <v-card-title><span>Nouveau courriel</span>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="addNewParagraph" x-small><v-icon color="primary"></v-icon>Ajouter un paragraphe</v-btn>
        </v-card-title>
        <v-card-text>
            <v-container>
                <form>
                    <div class="form-group">
                        <label>Objet:</label>
                        <v-text-field class="col-sm-8" :error-messages="objetMailError" dense outlined v-model.trim="$v.objetMail.$model"></v-text-field>
                        <small id="emailHelp" class="form-text text-muted">L'objet de votre courriel </small>
                    </div>

                    <div class="form-group">
                        <label>Titre:</label>
                        <v-text-field class="col-sm-8" :error-messages="titreMailError" dense outlined v-model.trim="$v.titreMail.$model"></v-text-field>
                        <small id="emailHelp" class="form-text text-muted">Le titre de votre courriel. </small>
                    </div>

                    <div class="form-group">
                        <label>paragraphes:</label>
                        <v-textarea 
                            v-for="(paragraph , p) in inputs" :key="p" 
                            class="col-sm-8" 
                            dense outlined v-model="paragraph.message" 
                            @click:append="deleteParagraph(p)"
                            append-icon="mdi-close">
                        </v-textarea>                    
                    </div>
                </form>
            </v-container>
        </v-card-text>

        <v-card-actions class="justify-end">
            <v-btn variant="text" x-small color="orange" @click="checkingAndSending(userTosend.email , objetMail , titreMail , inputs)"><span class="btn-label">Envoyer le mail</span></v-btn>
        </v-card-actions>

        <v-alert type="error" class="alert alert-error" dismissible>{{ errorMsg }}</v-alert>
    </v-card>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '../../Config/globalConst' 
import { required } from 'vuelidate/lib/validators'
export default {
    name:"EnvoieMail.vue",
    props:{
        userTosend:Object,
    },
    data(){
        return{
            errorMsg : null,

            paragrapheDynamicList:[],
            inputs : [{ message : null}],

            objetMail:null,
            titreMail : null,
        }
    },

    validations:{
        objetMail:{
            required,
        },

        titreMail:{
            required,
        }
    },

    methods:{
        
        // VERIFICATION ET ENVOI DU MAIL À UN UTILISATEUR
        checkingAndSending(usermail , objetMail , titreMail){
            this.$v.$touch();
            if(this.$v.objetMail.$invalid){
                this.$swal.fire("Envoi de mail", "L'objet du mail est obligatoire","error");
            }
            else if(this.$v.titreMail.$invalid){
                this.$swal.fire("Envoi de mail", "Le titre est obligatoire !!","error");
            }else{
                this.sendDynamicEmailToUser(usermail , objetMail , titreMail); //
            }
        },

        // AJOUTER UN NOUVEAU PARAGRAPHE
        addNewParagraph(){
            this.inputs.push({message: null,})
        },

        // SUPPRESSION D'UN PARAGRAPHE POUR LES MESSAGES À ENVOYER PAR MAIL.
        deleteParagraph(position){
            this.inputs.splice(position , 1);
        },

        //ENVOYER UN MAIL À UN UTILISATEUR SÉLECTIONNÉ PAR L'ADMINISTRATION.
        async sendDynamicEmailToUser(usermail , sujet , titre){
            this.overlay = true
            this.inputs.forEach(element => {
                this.paragrapheDynamicList.push(element.message);
            });
            let graphqlQuery = {
                query : `mutation{
                    sendDynamicMail(email: "${usermail}" , sujet:"${sujet}" , titre:"${titre}" , paragraphes:${JSON.stringify(this.paragrapheDynamicList)}){
                        success
                    }
                }`
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{ 
                if (response.data.data.sendDynamicMail.success === true) {
                    this.$swal.fire("Envoi de mail", "E-mail envoyé avec succes","success");
                }
            }).catch((e)=>{
                this.$swal.fire("Echec de l'envoi", e ,"error");
            }).finally(()=>{
                this.overlay = false ;
            })
        },

    }, 

    computed:{

        // GESTION DES CONTRAINTES
        objetMailError(){
            if (!this.$v.objetMail.$dirty) return [];
            const errors =  [] ;
            !this.$v.objetMail.required && errors.push("Quel est le type de votre message ?");
            return errors ;
        },

        titreMailError(){
            if (!this.$v.titreMail.$dirty) return [];
            const errors =  [] ;
            !this.$v.titreMail.required && errors.push("Le message ne peut être vide !");
            return errors ;
        },
    }
}
</script>

<style lang="scss" scoped>
    .btn-label{
        color:black
    }

    .alert{
        z-index: 2100;
        width: 20%;
    }

    .alert-error{
        position: fixed;
        display: none;
        right: 0;
    }
</style>