<template>
  <AriahHome/>
</template>

<script>
// Components
import AriahHome from '../components/AriahHome.vue';

export default {
  name: 'HomeView',
  components: {
    AriahHome,
  },
}
</script>
