<template>
    <v-app>
        <v-card>
            <v-card-title><small class="font-weight-bold">ENVOYER UNE NOTIFICATION</small></v-card-title>
            <v-card-text>
                <v-container>
                    <form>
                        <div class="form-group">
                            <label>Type de message:</label>
                            <v-text-field :error-messages="typeMessageError" dense outlined v-model.trim="$v.typeMessage.$model"></v-text-field>
                            <small id="emailHelp" class="form-text text-muted">C'est quoi votre message ? </small>
                        </div>

                        <div class="form-group">
                            <label>Votre Message</label>
                            <v-textarea :error-messages="notificationAlertError" outlined dense v-model.trim="$v.notificationAlert.$model"></v-textarea>
                            <small class="form-text text-muted">*Champs obligatoire.</small>
                        </div>
                                    
                        <div class="form-group">
                            <label>Champs URL:</label>
                            <v-text-field :error-messages="linkUserError" v-model.trim="$v.linkToNotify.$model" dense outlined type="text" placeholder="https://"></v-text-field>
                        </div>
                        <small id="emailHelp" class="form-text text-muted">NB : (*) devant un champs signifie qu'il est obligatoire.</small>
                    </form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="orange" small :disabled="btnSendNotification==true" @click="checkNotificationAndSendNotification(notificationAlert , linkToNotify , typeMessage ,  userNotified.userCode)"><span class="text-white">Envoyer la notification</span></v-btn>
            </v-card-actions>
        </v-card>
    </v-app>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import { baseUrl } from '../../Config/globalConst';
import $ from 'jquery'
export default {
    name:"AlertNotification",
    props:{
        userNotified:Object,
    },
    data(){
        return{
            typeMessage : null,
            notificationAlert: null,
            linkToNotify : null,

            btnSendNotification:false,
        }
    },

    validations:{
        typeMessage:{
            required
        },

        notificationAlert:{
            required
        },

        linkToNotify:{
            required
        }
    },

    methods:{

        

        // ENREGISTRER UNE NOTIFICATION DANS LE TABLEAU DE BORD
        async createNotification(description, link){
            let graphqlQuery = {
                query : `mutation{
                    sendStaticNotification(description: "${description}",  link: "https://${link}"){
                        success
                    }
                }`
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.data.data.sendStaticNotification.success === true){
                    this.$swal.fire('Notification enregistré' , 'Opération effectuée avec succes' , 'success')
                }else{
                    this.$swal.fire('Echec de l\'enregistrement de notification' , '' , 'error')
                }
            }).catch((e)=>{
                this.$swal.fire('Echec de l\'enregistrement de la notification' , e , 'error')
            })
        },

        checkNotificationAndSendNotification(description, link , avertissement , userCode){
            this.$v.$touch()
            if(this.$v.notificationAlert.$invalid){
                this.errorMsg = "Certains champs omis sont requis"
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }else if(this.$v.typeMessage.$invalid){
                this.errorMsg = "Certains champs omis sont requis"
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }
            else{
                if(this.linkToNotify != null){
                    if(this.linkToNotify.includes("https://")){
                        this.$swal.fire('Lien corrompu' , 'Entrer seulement le domaine', 'error')
                    }else{
                        this.alertAccount(description, link , avertissement , userCode);
                    }
                }else{
                    this.alertAccount(description, link , avertissement , userCode);
                }
            }
        },

        // ENVOYER UNE NOTIFICATION A UN COMPTE.
        async alertAccount(description, link , avertissement , userCode){
            this.btnSendNotification = true;
            let graphqlQuery = {
                query: `mutation{ sendNotificationToUser(cat : "Systeme" , body: "${description}", title: "${avertissement}" ,  link: "https://${link}" , userCode:"${userCode}"){
                        success
                    }
                }`
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if (response.data.data.sendNotificationToUser.success === true) {
                    this.$swal.fire('Notification envoyee' , "L'utilisateur a bien recu votre notification" , "success");
                    this.createNotification(description , link , userCode);
                }
            }).catch((e)=>{
                this.$swal.fire('Notification non envoyee' , e , 'error')
            }).finally(()=>{
                this.btnSendNotification = false;
            });
        },
    },
    

    computed:{
        // GESTION DES CONTRAINTES POUR L'ENVOI DE NOTIFICATIONS
        typeMessageError(){
            if (!this.$v.typeMessage.$dirty) return [];
            const errors =  [] ;
            !this.$v.typeMessage.required && errors.push("Quel est le type de votre message ?");
            return errors ;
        },

        notificationAlertError(){
            if (!this.$v.notificationAlert.$dirty) return [];
            const errors =  [] ;
            !this.$v.notificationAlert.required && errors.push("Le message ne peut être vide !");
            return errors ;
        },

        linkUserError(){
            if (!this.$v.linkToNotify.$dirty) return [];
            const errors =  [] ;
            !this.$v.linkToNotify.required && errors.push("Le lien est obligatoire ");
            return errors ;
        },
    }
}
</script>

<style scoped>

</style>