//export const baseUrl  = "https://busolapi.sectester.dev/graphql";
//export const baseUrl  = "https://dev.sectester.io/graphql";
export const baseUrl  = "https://api.busolapp.com/graphql"
export const administratorMails = [
    "isaac.tra@icloud.com", "gerardphilippe44@gmail.com","emmanuelhervekouevi@gmail.com", "gowleytizie@proton.me"
];


// PERMETTANT DE PASSER EN MODE DEVELOPPEMENT OU PRODUCTION DE L'APPLICATION
/*export const changeUrlMode = (mode) => {
    let urlMode = "";
    mode == true ? urlMode = baseUrl : urlMode = baseUrlDev ;
    return urlMode ;
}*/
/*-------------------------------------STRING MUTATION OU REQUEST------------------------------------------------------------------------------*/

// CONSULTER TOUS LES BUGS SIGNALÉS
export const allBugs = `query {
    allBug{
        edges{
            node{
                bugCode,
                bugCat,
                modelCv,
                description,
                resolved,
                user{
                    userCode,
                    photo,
                    email,
                    name,
                    firstname,
                    birthDate,
                    sex
                },
                creationDate
            }
        }
    }    
}`

// RECUPERER LES INFORMATIONS DE CV PAR UTILISATEURS
export const cvInfosByUser = (groupeBy , cvModel) => `query {
    cvInfosByUser(groupBy:"${groupeBy}" , cvModel:"${cvModel}"){
        groupKey,
        userCounts{
            user{
                userCode,
                name,
                firstname,
                levelStudy,
                email,
                photo,
            },
            downloadCount,
            shareCount,
            updateCount
        }
    }
}`

// RECUPERER LES INFORMATIONS DE CV PAR MODEL
export const userConnect = (groupeBy) => `query {
    userConnect(groupBy:"${groupeBy}"){
        groupKey,
        groupValue{
            user{
            userCode,
            photo,
            name,
            firstname,
            levelStudy,
            creationDate
            }
        }
        
    }
}`

//--------------------------------------------------------REQUEST FOR USERS ---------------------------------------------------------------//

export const cvInfosByModel = (groupeBy) => `query {
    cvInfosByModel(groupBy:"${groupeBy}"){
        groupKey,
        users{
            name
            firstname
        },
        downloadCount
        shareCount
        updateCount
        
    }
}`


//-----------------------------------------------------------------PARAINAGE APIs---------------------------------------------------------------//

export const referralUsedUsersVerified = (userCode) => `query {
    referralUsedUsersVerified(userCode:"${userCode}"){
        name,
        firstname,
        email
        
    }
}`


export const referralUsedUsers = (userCode) => `query {
    referralUsedUsersVerified(userCode:"${userCode}"){
        name,
        firstname,
        email
        
    }
}`


export const referralUsedCount = (userCode) => `query {
    referralUsedCount(userCode:"${userCode}")
}`



export const referralUsedVerifiedCount = (userCode) => `query {
    referralUsedVerifiedCount(userCode:"${userCode}")
}`
