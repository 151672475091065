<template>
    <v-app>
        <v-container>
            <v-card>
                <v-card-title><span>GESTION DES JETONS</span>
                    <v-spacer></v-spacer>
                    <v-btn x-small color="primary" @click="openBox">AJOUTER UN TOKEN</v-btn>
                </v-card-title>
                <v-card-subtitle>Liste des tokens disponibles</v-card-subtitle>
                <v-data-table :headers="headers" :items="tokenList" :loading="loading">
                    <template v-slot:[`item.actions`]="{item}">                
                        <v-icon title="Editer" color="blue" class="mr-3" small @click="editToken(item)">mdi-pencil-outline</v-icon>
                        <v-icon title="Supprimer" color="red" class="mr-3" small @click="deleteToken(item.label)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
            </v-card>
        </v-container><br>

        <v-container>
            <v-card width="500" class="box_token">
                <v-card-title><small>Gérer vos tokens</small>
                    <v-spacer></v-spacer>
                    <v-btn x-small icon @click="closeBox"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle>
                    <span>Ajouter et Modifier vos tokens pour faciliter l'administration.</span>
                </v-card-subtitle>
                
                <v-card-text>
                    <form>
                        <div class="mb-3 col-lg-6">
                            <label for="exampleInputEmail1" class="form-label">Token</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="tokenOject.label">
                        </div>

                        <div class="mb-3 col-lg-6">
                            <label for="exampleInputPassword1" class="form-label">Prix</label>
                            <input type="text" class="form-control" id="exampleInputPassword1" v-model="tokenOject.price">
                        </div>

                        <div class="col-lg-5">
                            <v-btn v-if="createMode == true" x-small color="success" 
                                @click="createToken(tokenOject.label, tokenOject.price)">
                                <span class="font-weight-bold">AJOUTER LE TOKEN</span>
                            </v-btn>
                            <v-btn v-else x-small color="success" @click="updateToken(tokenOject.label, tokenOject.price)">
                                <span class="font-weight-bold">MODIFIER LE TOKEN</span>
                            </v-btn>
                        </div>
                        
                    </form>
                </v-card-text>
            </v-card>
        </v-container>

        <v-alert type="success" class="alert alert-success" dismissible>{{ successMsg }}</v-alert>
        <v-alert type="error" class="alert alert-error" dismissible>{{ errorMsg }}</v-alert>

        <v-overlay :value="overlay">
            <v-progress-circular color="orange" indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-app>
</template>


<script>
import axios from 'axios';
import $ from 'jquery'
import { baseUrl } from '@/Config/globalConst';
export default{
    name: "JetonsVue",
    data(){
        return{
            tokenList : [],

            createMode: false,

            overlay:false,

            errorMsg : null,

            successMsg : null,

            warningMsg : null,

            tokenOject:{
                label:null,
                price:null
            },

            loading: false,

            search:"",

            headers:[
                { text: 'Libelle', value: 'label' },
                { text: 'Prix', value: 'price' },
                { text: 'Actions', value: 'actions', sortable:false },
            ],
        }
    },

    methods:{

        //Modifier un token(Service Web)
        async updateToken(label, price){
            this.overlay = true
            let graphqlQuery = {
                query : `mutation{
                    updatePrice(label: "${label}" , price: ${parseInt(price)}){
                        success
                        error
                    }
                }`
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if (response.data.data.updatePrice.success === true) {
                    this.successMsg = "Modification éffectuée";
                    $('.alert-success').fadeIn();
                    setTimeout(() => {
                        $('.alert-success').fadeOut();
                    }, 4000);
                    this.getAllTokens();
                }else{
                    this.errorMsg = response.data.data.updatePrice.error;
                    $('.alert-error').fadeIn();
                    setTimeout(() => {
                        $('.alert-error').fadeOut();
                    }, 4000);
                }
            }).catch((e)=>{
                this.$swal.fire("Echec de l'envoi", e ,"error");
            }).finally(()=>{
                this.overlay = false ;
                setTimeout(() => {
                    $('.box_token').fadeOut();
                }, 800);
            })
        },


        //Modifier un token(Service Web)
        async createToken(label,price){
            this.overlay = true
            let graphqlQuery = {
                query: `mutation{
                    createPrice(label: "${label}" , price: ${parseInt(price)}){
                        success
                        error
                    }
                }`
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if (response.data.data.createPrice.success === true) {
                    this.successMsg = "Opération Reussie";
                    $('.alert-success').fadeIn();
                    setTimeout(() => {
                        $('.alert-success').fadeOut();
                    }, 4000);
                    this.getAllTokens();
                }else{
                    this.errorMsg = "Echec de l'opération";
                    $('.alert-error').fadeIn();
                    setTimeout(() => {
                        $('.alert-error').fadeOut();
                    }, 4000);
                }
            }).catch((e)=>{
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.overlay = false ;
                setTimeout(() => {
                    $('.box_token').fadeOut();
                }, 800);
            })
        },

        //Supprimer un token(Service Web)
        async deleteToken(label){
            this.$swal({
                    title: "Voulez vous supprimer ?", text: "Êtes vous sure ? Attention cette action est irréversible",
                    type: "Warning", showCancelButton: true,
                    confirmButtonColor: "#c0392b",confirmButtonText: "Oui, supprimer!"
                }).then((result) => {
                this.overlay = true;
                if (result.value) {
                    let graphqlQuery = {
                        query : `mutation{
                            deletePrice(label:"${label}"){
                                success
                                error
                            }
                        }`
                    }
                    axios.post(baseUrl, graphqlQuery).then((response) =>{
                        if(response.data.data.deletePrice.success === true){
                            this.$swal.fire('Suppression reussie' , "L' utilisateur a été rechargé"  , 'success')
                        }else{
                            this.$swal.fire('Echec de la suppression' , response.data.data.deletePrice.error , 'error')
                        }
                    }).catch((e)=>{
                        this.$swal.fire('Echec de la suppression' , e , 'error')
                    }).finally(()=>{
                        this.overlay = false
                        this.getAllTokens();
                    })
                }
            })
        },

        //Modifier un token
        editToken(item){
            this.createMode = false;
            this.tokenOject.label = item.label
            this.tokenOject.price = item.price
            setTimeout(() => {
                $('.box_token').fadeIn();
            }, 1000);
        },

        // Recuperer la liste des tokens disponibles
        async getAllTokens(){
            this.loading = true
            let graphqlQuery = {
                "query" : "query{getAllPrice{label price} }"
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.tokenList = response.data.data.getAllPrice;
                }
                else{
                    this.tokenList = [];
                }
            }).catch((e)=>{
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.loading = false
            })
        },
        
        // OUVERTURE DE LA BOX DES TOKENS
        openBox(){
            this.tokenOject.label = null
            this.tokenOject.price = null
            this.createMode = true
            setTimeout(() => {
                $('.box_token').fadeIn();
            }, 800);
        },

        // FERMETURE DE LA BOX DES TOKENS
        closeBox(){
            setTimeout(() => {
                $('.box_token').fadeOut();
            }, 800);
        }
    },


    mounted(){
        this.getAllTokens();
    }
}

</script>


<style>
    .box_token{
        display: none;
    }

    .alert{
        z-index: 2100;
        width: 20%;
    }

    .alert-success{
        position: fixed;
        display: none;
        right: 0;
    }

    .alert-error{
        position: fixed;
        display: none;
        right: 0;
    }
</style>