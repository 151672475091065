<template>
    <v-card>
        <v-container>
            <div class="row">
                <div class="col-sm-4">
                    <v-row justify="center">
                        <v-avatar class="mt-5" size="130" v-if="user.photo == null">
                            <v-img src="../../assets/userphoto.svg"></v-img>
                        </v-avatar>

                        <v-avatar class="mt-5" size="130" v-else>
                            <v-img :src="user.photo"></v-img>
                        </v-avatar>
                    </v-row><br>
                                    
                    <v-list dense>
                        <v-list-item-group color="orange">
                            <v-list-item link hover @click="componentSelected = 'informations personnelles'">
                                <v-list-item-icon>
                                    <v-icon>mdi-information-variant</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title><span class="item-list">INFORMATIONS</span></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>

                            <v-list-item link @click="componentSelected = 'activités'">
                                <v-list-item-icon>
                                    <v-icon>mdi-swap-horizontal</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title><span class="item-list">ACTIVITÉS</span></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>

                            <v-list-item link @click="componentSelected = 'transfert de tokens'">
                                <v-list-item-icon><v-icon>mdi-swap-vertical-bold</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title><span class="item-list">TRANSFERT DE TOKENS</span></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>

                            <v-list-item link @click="componentSelected = 'alerter le compte'">
                                <v-list-item-icon><v-icon>mdi-bell</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title><span class="item-list">ALERTER LE COMPTE</span></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>

                            <v-list-item link @click="componentSelected = 'envoyer un mail'">
                                <v-list-item-icon><v-icon>mdi-mail</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title><span class="item-list">ENVOYER UN MAIL</span></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>

                <v-divider></v-divider>

                <div class="col-sm-8">
                    <div v-if="componentSelected == 'informations personnelles'">
                        <UserInfo :profilUser="user"/>
                    </div>
                    <div v-if="componentSelected == 'activités'">
                        <UserInfo :profilUser="user"/>
                    </div>
                    <div v-if="componentSelected == 'transfert de tokens'">
                        <TransfertToken :userTransaction="user"/>
                    </div>
                    <div v-if="componentSelected == 'alerter le compte'">
                        <AlertNotification :userNotified="user"/>
                    </div>
                    <div v-if="componentSelected == 'envoyer un mail'">
                        <EnvoieMailVue :userTosend="user"/>
                    </div>
                </div>
            </div>
        </v-container>
    </v-card>
</template>

<script>
import UserInfo from "../custom_components/UserInfo.vue"
import AlertNotification from "../custom_components/AlertNotification.vue"
import TransfertToken from "../custom_components/TransfertToken.vue"
import EnvoieMailVue from "../custom_components/EnvoieMail.vue"
export default {
    components:{
        UserInfo,
        AlertNotification,
        TransfertToken,
        EnvoieMailVue
    },
    props:{
        user:Object
    },
    
    data(){
        return{
            componentSelected : "informations personnelles"
        }
    }
}
</script>

<style scoped>
    .item-list{
        font-weight: bold !important;
        font-size: 12px;
        border-radius: 5px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
    }

</style>