import { render, staticRenderFns } from "./AlertNotification.vue?vue&type=template&id=1f01433d&scoped=true"
import script from "./AlertNotification.vue?vue&type=script&lang=js"
export * from "./AlertNotification.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f01433d",
  null
  
)

export default component.exports